@import '../Spot.scss';

.spot-data-table + .spot-paging {
    margin-top: 21px;
    justify-content: flex-end;
}

.spot-data-table__sort-icon {
    height: 9px;
    width: 9px;
    margin-left: 6px;
}

.spot-data-table--sortable th:hover {
    color: $spot-color-neutral-12;
}

.spot-data-table > tbody > tr:last-child > td {
    border-bottom: 0;
}
