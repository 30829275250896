@import '../../../../../../../node_modules/@idexx/spot/dist/styles/shared/spot_tokens';

.addAccount {
    padding: 10px 0;
}

.accountInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.editPracticeLink {
    width: 100%;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: right;
}

.editIconOrg {
    width: 15px;
    height: 15px;
    fill: #00a7b5;
}

.editButtonOrg {
    padding-left: 5px;
}
