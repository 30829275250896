@import '../../../../../../../node_modules/@idexx/spot/dist/styles/shared/spot_tokens';

.leftPracticeEditPane {
  padding: 10px 20px 0px 30px;
  float: left;
  width: 50%;
}

.rightPracticeEditPane {
  padding: 10px 30px 0px 30px;
  float: right;
  width: 50%;
}

.textBoxContainer {
  padding-bottom: 20px;
}

.siteIdName {
  font-size: 25px;
  padding-left: 30px;
  padding-bottom: 20px;
}

.siteIdText {
  font-size: 16px;
  font-weight: bold;
  padding-left: 30px;
  padding-top: 20px;
}

.cancelButton {
  flex: auto;
}

@media (max-width: 1500px) {
  .editPracticeContainer {
    width: 650px;
  }
}