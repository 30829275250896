.login-iframe {
  width: 400px;
  height: 600px;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.unauthorized-header {
  color: $spot-color-red-60;
  margin-top: 100px;
}
