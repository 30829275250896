.dataGrid {
    padding-left: 80px;
    padding-right: 80px;
}

.headerContainer {
    align-items: center;
}

.headerCell {
    overflow-wrap: break-word;
    font-weight: bold;
    font-size: 15px;
}

.rowCell {
    font-weight: 400;
    line-height: 1.5;
    font-size: 15px;
    text-align: left;
}
