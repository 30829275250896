@import '../../../../Spot/Spot.scss';

.user-table {
    .ellipsis-overflow {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .word-break-overflow {
        word-break: break-word;
    }
    width: 800px;

    .add-user-button {
        margin-left: 15px;
        margin-bottom: 5px;
        display: inline-block;
    }
}

.user-table .spot-data-table {
    width: 100%;
    font-size: 16px;

    th,
    td {
        padding: 8px;
    }

    td {
        cursor: unset;
        vertical-align: top;
    }

    .column-0,
    .column-1 {
        width: 370px;
    }

    .column-2 {
        width: 20%;
    }

    .column-3 {
        width: 6%;
    }

    > tbody > tr {
        border-top: 1px solid #babdbf;

        > td,
        > td:first-child {
            border: none;
        }
    }

    > tbody > tr:hover {
        background-color: $spot-interactive-color-hover-on-light-primary;
        cursor: pointer;
    }

    > thead > tr > th {
        border: none;
    }
}