.communicator-user-edit-create-modal-page {
  .user-edit-delete {
    padding-left: 20px;
    .user-edit-delete-icon {
      width: 15px;
      height: 15px;
      fill: #00a7b5;
      margin-right: 7px;
      margin-bottom: 5px;
    }
  }

  .user-edit-delete-black {
    padding-left: 20px;
    color: black;
    .user-edit-delete-icon {
      width: 15px;
      height: 15px;
      fill: black;
      margin-right: 7px;
      margin-bottom: 5px;
    }
  }

  .user-save-button {
    float: right;
    margin-top: 10px;
  }

  .loading-screen {
    width: 560px;
    height: 350px;
    .loading-spinner {
      position: fixed;
      top: 49%;
      left: 48%;
    }

  }

  .button-footer {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .delete-yes-no {
    width: 100px;
    height: 43px;
    display: inline-block;
    margin-left: 50px;

    .delete-yes {
      float: left;
      color: #00a7b5;
    }

    .delete-no {
      float: right;
      color: #00a7b5;
    }
  }
}
