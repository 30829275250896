@import '~@idexx/spot/dist/styles/shared/spot_tokens';
@import '~@idexx/spot/dist/styles/shared/util';
@import '~@idexx/spot/dist/styles/shared/typography';
@import '~@idexx/spot/dist/styles/shared/color';

.holder {
    width: 100%;

    .grid {
        border: 0;
        width: 100%;

        :global(.MuiDataGrid-columnsContainer) {
            overflow: visible;
            border: 0;
            background-color: $spot-background-color-light-secondary;
        }

        :global(.MuiDataGrid-colCellWrapper) {
            overflow: visible;
        }

        :global(.MuiDataGrid-colCellTitleContainer) {
            overflow: visible;
        }

        :global(.MuiDataGrid-menuIcon) {
            visibility: visible;
        }

        :global(.MuiDataGrid-cell) {
            &:focus,
            &:focus-within {
                outline: none;
            }
        }

        :global(.MuiDataGrid-columnHeader) {
            &:focus,
            &:focus-within {
                outline: none;
            }
        }

        :global(.MuiDataGrid-columnSeparator) {
            visibility: hidden;
        }

        :global(.MuiCircularProgress-indeterminate) {
            z-index: 1;
        }

        :global(.MuiDataGrid-columnHeaderSortable .MuiDataGrid-iconButtonContainer) {
            display: flex;
        }

        :global(.MuiDataGrid-row) {
            &:hover {
                background-color: #e5f6f7;
            }

            &:global(.Mui-selected) {
                background-color: transparent;
            }
        }

        :global(p.MuiTablePagination-displayedRows) {
            margin-bottom: 0px;
        }

        :global(p.MuiTablePagination-selectLabel) {
            margin-bottom: 0px;
        }
    }
}

.noRows {
    @include level-5-heading();
    @include spot-text-color-secondary();

    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    z-index: -1;
}
