@import '../Spot.scss';

.spot-paging__group {
    box-shadow: unset;

    .spot-paging__button:last-child {
        border-right: 1px solid #ddd;
    }
}

.spot-paging__button {
    padding: 6px 12px;
    line-height: 1.42857;
    color: $spot-color-teal-35;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
    max-width: unset;

    &:hover {
        color: $spot-color-teal-35;
        background-color: #eeeef1;
        z-index: 2;
        border-color: #ddd;
    }
}

.spot-paging__button--selected {
    z-index: 3;
    color: #fff;
    background-color: $spot-color-teal-35;
    border-color: $spot-color-teal-35;

    &:hover {
        color: #fff;
        background-color: $spot-color-teal-35;
        border-color: $spot-color-teal-35;
    }
}
