.fly-over-container {
    position: fixed;
    min-width: 40%;
    background-color: $spot-color-neutral-97;
    box-shadow: -2px 0px 10px 0px $spot-color-neutral-81;
    top: 0;
    right: 0;
    height: 100vh;
    overflow-y: auto;
    z-index: 800;

    @media only screen and (max-width: 768px) {
        left: 0;
        width: 100vw;
    }

    .fly-over-list-item {
        border-top: $spot-color-neutral-97 solid 2px;
        padding-top: 5px;
        font-size: 0.9em;
    }

    .fly-over-content {
        width: 100%;
        background-color: white;

        .fly-over-header {
            h4 {
                margin: 0;
            }
            border-bottom: 2px solid $spot-color-neutral-97;
            padding: 20px 25px;
        }

        .fly-over-form {
            padding: 25px;
        }
    }

    .fly-over-actions {
        background-color: $spot-color-neutral-97;
        display: flex;
        justify-content: flex-end;
        padding: 20px;
        width: 100%;
    }

    .fly-over-section-title {
        font-weight: bold;
        display: block;
    }

    .fly-over-alert-notification {
        margin-right: 5px;
        width: 15px;
        height: 15px;
        fill: $spot-color-yellow-59;
        pointer-events: none;
    }

    .fly-over-header-cancel {
        width: 20px;
        height: 20px;
        fill: $spot-color-neutral-81;
    }

    .fly-over-editable-container {
        display: inline-flex;
        align-items: center;

        .fly-over-editable {
            width: $spot-size-icon-xs;
            height: $spot-size-icon-xs;
            fill: $spot-interactive-color-on-light;
            margin: -5px 0 0 5px;
        }

        a {
            cursor: pointer;
        }
    }

    .fly-over-x {
        display: inline-block;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .fly-over-group {
        padding-left: 10px;
        margin-bottom: 25px;
        color: $spot-color-neutral-42;
        .row {
            margin-top: 5px;
        }
    }

    .label-text {
        font-size: 80%;
        font-weight: bold;
        text-transform: uppercase;

        &--label {
            @extend .label-text;
            font-size: 0.9em;
            margin-right: 5px;
        }
    }

    .fly-over-spot-loading {
        height: 100vh;
        margin-top: 25vh;
        text-align: center;
    }
}
